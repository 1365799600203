const ListaSopralluogo = () => import('@/pages/core/sopralluogo/ListaSopralluogo');
const CreaSopralluogo = () => import('@/pages/core/sopralluogo/CreaSopralluogo');
const CreaSopralluogoDev = () => import('@/pages/core/sopralluogo/CreaSopralluogo.dev.vue');
const ModificaSopralluogo = () => import('@/pages/core/sopralluogo/ModificaSopralluogo');

const routes = [
    { 
        name: 'sopralluogo.create',
        path: '/sopralluogo/create/:clienteId?', 
        component: CreaSopralluogo,
        meta: {
            titolo: 'Nuovo Sopralluogo',
            requiresAuthentication: true,
            dashboard: false,
            permesso: 'sopralluogo.create'
        }
    },
    { 
        name: 'sopralluogo.create.dev',
        path: '/sopralluogo/create/dev/:clienteId?', 
        component: CreaSopralluogoDev,
        meta: {
            titolo: 'Nuovo Sopralluogo - DEV',
            requiresAuthentication: true,
            dashboard: false,
            permesso: 'sopralluogo.create'
        }
    },
    { 
        name: 'sopralluogo.index',
        path: '/sopralluogo', 
        component: ListaSopralluogo,
        meta: {
            titolo: 'Lista Sopralluoghi',
            requiresAuthentication: true,
            dashboard: false,
            permesso: ['sopralluogo.index', 'sopralluogo.utente.index']
        }
    },
    { 
        name: 'sopralluogo.edit',
        path: '/sopralluogo/:sopralluogoId/edit', 
        component: ModificaSopralluogo,
        meta: {
            titolo: 'Sopralluogo',
            requiresAuthentication: true,
            dashboard: false,
            permesso: 'sopralluogo.show'
        }
    },

]

export default routes;