const ListaAssistenza = () => import('@/pages/assistenza/ListaAssistenza.vue');
const ModificaAssistenza = () => import('@/pages/assistenza/ModificaAssistenza.vue');
const CreaAssistenza = () => import('@/pages/assistenza/CreaAssistenza.vue');

const routes = [
    { 
        name: 'assistenza.index',
        path: '/assistenza', 
        component: ListaAssistenza,
        meta: {
            titolo: 'Chiamate Assistenza Cliente',
            requiresAuthentication: true,
            dashboard: false,
            permesso: ['assistenza.index', 'assistenza.utente.index']
        }
    },
    { 
        name: 'assistenza.edit',
        path: '/assistenza/:assistenzaId/edit', 
        component: ModificaAssistenza,
        meta: {
            titolo: 'Modifica Chiamata Assistenza Cliente',
            requiresAuthentication: true,
            dashboard: false,
            permesso: 'assistenza.show'
        }
    },
    { 
        name: 'assistenza.create',
        path: '/assistenza/create/:clienteId?/:ordineId?', 
        component: CreaAssistenza,
        meta: {
            titolo: 'Nuova Chiamata Assistenza Cliente',
            requiresAuthentication: true,
            dashboard: false,
            permesso: 'assistenza.create'
        }
    },
]

export default routes;