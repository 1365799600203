<template>
    <v-app>
        <ClerkLoaded>
            <the-header></the-header>

            <router-view v-slot="slotProps">
                <transition name="route" mode="out-in">
                    <component :is="slotProps.Component"></component>
                </transition>
            </router-view>

            <the-footer></the-footer>
        </ClerkLoaded>
    </v-app>
</template>

<script>
import TheHeader from './components/UI/layout/TheHeader.vue';
import TheFooter from './components/UI/layout/TheFooter.vue';

import { ClerkLoaded } from 'vue-clerk';

export default {
    name: 'App',
    components: {
        TheHeader,
        TheFooter,
        ClerkLoaded
    },
    beforeCreate() {
        // this.$store.commit('initialiseStore');
    }
}
</script>


<style>
@import 'datatables.net-bs5';

.custom_titolo {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: 3px;
    text-transform: uppercase;
}
.custom_replace_loading_div {
    width: 40px;
    height: 32px;
}

* {
    box-sizing: border-box;
}

html {
    font-family: Arial, sans-serif;
}

body {
    background-color: #ddd;
    margin: 0;
    padding: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 !important;
}

.v-row {
    margin: 0 !important;
}

.v-container,
.v-col {
    padding: 0 !important;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.vuetify-tooltip .v-overlay__content {
    background-color: #000 !important;
}

.custom_error_field {
    /* border: 1px solid rgba(255, 0, 0, 0.683); */
    border-radius: 4px;
    background: linear-gradient(180deg, #fc42004d, #ff00002e) !important;
    /* box-shadow: 0 0 12px rgba(0, 0, 0, 0.3); */
    box-shadow: 0 1px 4px rgb(0 0 0 / 66%);
}

.custom_default_input {
    background-color: #F6F6F6;
    border-bottom: 1px solid #00000061;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    min-height: 40px;
    max-height: 56px;
    height: 100%;
}

.unico_salvataggio {
    border-bottom: 1px solid #0000009b;
}

/* .route-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.route-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
} */

.helper_item {
    text-decoration: none;
    color: black;
}

::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
    height: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #003d88;
    /* border: 1px solid #000; */
    border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #004599b0;
}

.titolo_secondo_livello {
    font-size: 16px;
}

.style_titolo {
    font-size: large;
}

.style_descrizione {
    font-size: small;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {}
</style>